@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply bg-background;
    }
  }